import { EntityViewSet, Headers } from "../generic";
import type {
  NovelParagraphEntity,
  NovelRevisionCreateParams,
  NovelRevisionEntity,
  NovelRevisionListParams,
  NovelRevisionParagraphListParams,
  NovelRevisionUpdateParams,
  Result,
} from "../../types";
import axios from "~/src/api/core/axios";
import { assertSuccess, paramsParser } from "~/src/api";

export class NovelRevision extends EntityViewSet<
  NovelRevisionListParams,
  NovelRevisionCreateParams,
  NovelRevisionUpdateParams,
  NovelRevisionEntity
> {
  constructor(path: string) {
    super(path + "/revision");
  }

  async findParagraphs(
    revisionId: string,
    params: NovelRevisionParagraphListParams,
    auth?: string,
  ) {
    const resp = await axios.get<Result<NovelParagraphEntity>>(
      this.apiPath + `/${revisionId}/paragraph` + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  async updateParagraphs(
    id: string,
    body: {
      add?: string[];
      remove?: string[];
    },
    auth: string,
  ) {
    const resp = await axios.post<Result<NovelRevisionEntity>>(
      this.apiPath + `/${id}/paragraph`,
      body,
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }
}
