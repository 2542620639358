<template>
  <NotificationsItem
    :is-unread
    :href
    :action-icon="IconAward"
    :date="notification.date"
  >
    <template #avatar>
      <AsyncImage
        :src="
          getImageUrl(
            notification.data.achievement.id,
            'achievement',
            'avatar',
            notification.data.achievement.attributes.avatarFileName,
            '128',
          ) ?? getAbsoluteAssetLink('nami/banner/great-wave.png')
        "
        class="w-10 h-10 object-cover rounded-md"
      />
    </template>
    <template #content>
      <div class="space-y-1">
        <p class="line-clamp-2">
          {{ t("notifications.achievement_unlocked.title") }}
        </p>
        <p class="text-sm text-neutral-600 dark:text-neutral-400 line-clamp-2">
          <i18n-t keypath="notifications.achievement_unlocked.body">
            <template #achievement>
              <span class="text-neutral-800 dark:text-neutral-200">
                {{
                  extractFromLocale(
                    locale,
                    notification.data.achievement.attributes.name,
                  )
                }}
              </span>
            </template>
          </i18n-t>
        </p>
      </div>
    </template>
  </NotificationsItem>
</template>

<script setup lang="ts">
import { IconAward } from "@tabler/icons-vue";
import type { Props } from "../NotificationsItem.vue";
import type { NamiNotificationAchievementUnlocked } from "~/src/notifications/types/nami/achievement-unlocked";

defineProps<
  {
    notification: NamiNotificationAchievementUnlocked;
  } & Props
>();

const { locale, t } = useNuxtApp().$i18n.global;
const { getImageUrl } = useMediaLink();
</script>

<style>
.notification-avatar {
  /* We can only use px values on path() alas */
  clip-path: path("M 0 0 L 40 0 40 24 C 30,24 24,30 24,40 L 0 40 L 0 0 Z");
}
</style>
