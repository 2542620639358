import type { NotificationType } from "./notifications";
import type { NamiNotificationCreatorCommentToChapter } from "./nami/creator-comment-to-chapter";
import type { NamiNotificationCreatorCommentToTitle } from "./nami/creator-comment-to-title";
import type { NamiNotificationCreatorReactionToChapter } from "./nami/creator-reaction-to-chapter";
import type { NamiNotificationCreatorTitleFollow } from "./nami/creator-title-follow";
import type { NamiNotificationOfficialAnnouncement } from "./nami/official-announcement";
import type { NamiNotificationPaymentOrganizationSubscription } from "./nami/payment-organization-subscription";
import type { NamiNotificationReaderChapterPublishedToFollowedTitle } from "./nami/reader-chapter-published-to-followed-title";
import type { NamiNotificationSocialNewFollowData } from "./nami/social-new-follow";
import type { NamiNotificationSocialReactionToComment } from "./nami/social-reaction-to-comment";
import type { NamiNotificationSocialReactionToPost } from "./nami/social-reaction-to-post";
import type { NamiNotificationSocialReplyToComment } from "./nami/social-reply-to-comment";
import type { NamiNotificationSocialReplyToPost } from "./nami/social-reply-to-post";
import type { NamiNotificationSocialRepostOfPost } from "./nami/social-repost-of-post";
import type { NamiNotificationTestNotification } from "./nami/test-notification";
import type { NamiNotificationAchievementUnlocked } from "./nami/achievement-unlocked";

export enum NamiNotificationType {
  /** When a new comment has been posted on the creator's chapter */
  CreatorCommentToChapter,
  /** When a new comment has been posted on the creator's title */
  CreatorCommentToTitle,
  /** When a user reacts to the creator's chapter */
  CreatorReactionToChapter,
  /** When a new user has followed the creator's title */
  CreatorTitleFollow,
  /** A system message, or announcement */
  OfficialAnnouncement,
  /** When a user subscribes the creator's tiers */
  PaymentOrganizationSubscription,
  /** When a new chapter is published to the logged-in user's followed titles */
  ReaderChapterPublishedToFollowedTitle,
  /** When a user follows the logged-in user */
  SocialNewFollow,
  /** When a user reacts to the logged-in user's comment */
  SocialReactionToComment,
  /** When a user reacts to the logged-in user's post */
  SocialReactionToPost,
  /** When a user replies to the logged-in user's comment */
  SocialReplyToComment,
  /** When a user replies to the logged-in user's post */
  SocialReplyToPost,
  /** When a user reposts the logged-in user's post */
  SocialRepostOfPost,
  /** When a user unlocks an achievement */
  AchievementUnlocked,
  /** A test notification */
  TestNotification,
}

export type NamiNotificationDetails =
  | NamiNotificationCreatorCommentToChapter
  | NamiNotificationCreatorCommentToTitle
  | NamiNotificationCreatorReactionToChapter
  | NamiNotificationCreatorTitleFollow
  | NamiNotificationOfficialAnnouncement
  | NamiNotificationPaymentOrganizationSubscription
  | NamiNotificationReaderChapterPublishedToFollowedTitle
  | NamiNotificationSocialNewFollowData
  | NamiNotificationSocialReactionToComment
  | NamiNotificationSocialReactionToPost
  | NamiNotificationSocialReplyToComment
  | NamiNotificationSocialReplyToPost
  | NamiNotificationSocialRepostOfPost
  | NamiNotificationAchievementUnlocked
  | NamiNotificationTestNotification;

export type NamiNotification = {
  type: NotificationType.Nami;
  notification: NamiNotificationDetails;
};
