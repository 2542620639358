import { EntityView, EntityViewSet, Headers } from "../generic";
import type {
  MessageResult,
  NovelBookmarkCreateParams,
  NovelBookmarkEntity,
  NovelBookmarkListParams,
  NovelParagraphEntity,
  Result,
} from "../../types";
import { assertSuccess } from "../../core";
import axios from "../../core/axios";

// These are also used for highlights
class NovelBookmarkClass extends EntityViewSet<
  NovelBookmarkListParams,
  NovelBookmarkCreateParams,
  {},
  NovelBookmarkEntity
> {
  constructor() {
    super("/novel/bookmark");
  }

  override async update(
    id: string,
    body: {},
    auth: string,
  ): Promise<NovelBookmarkEntity> {
    throw new Error("Bookmarks are immutable");
  }

  // temporary override until be adds a flat path
  override async create(
    body: NovelBookmarkCreateParams,
    auth: string,
  ): Promise<NovelBookmarkEntity> {
    const resp = await axios.post<Result<NovelBookmarkEntity>>(
      `/novel/${body.novelChapterId}/paragraph/${body.novelChapterParagraphId}/bookmark/create`,
      body,
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  override async delete(id: string, version: number = 0, auth: string) {
    const resp = await axios.delete<MessageResult>(this.apiPath + `/${id}`, {
      responseType: "json",
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data);
  }
}

export const NovelBookmark = new NovelBookmarkClass();
