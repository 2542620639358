import { EntityView, EntityViewSet, Headers } from "../generic";
import type {
  CollectionResult,
  NovelChapterApprovalEntity,
  NovelChapterApprovalListParams,
  NovelChapterCreateParams,
  NovelChapterEntity,
  NovelChapterListParams,
  NovelChapterSearchParams,
  NovelChapterUpdateParams,
  NovelParagraphEntity,
  NovelRevisionParagraphListParams,
  ReportAction,
  Result,
} from "../../types";
import {
  assertSuccess,
  NovelParagraph,
  NovelRevision,
  paramsParser,
} from "~/src/api";
import axios from "~/src/api/core/axios";

class NovelChapterClass extends EntityViewSet<
  NovelChapterListParams,
  NovelChapterCreateParams,
  NovelChapterUpdateParams,
  NovelChapterEntity
> {
  constructor() {
    super("/novel");
  }

  async publish(
    id: string,
    body: { version: number; publishAt?: string },
    auth: string,
  ) {
    const resp = await axios.post<Result<NovelChapterEntity>>(
      this.apiPath + `/${id}/submit`,
      body,
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  async search(params: NovelChapterSearchParams = {}, auth?: string) {
    const resp = await axios.get<CollectionResult<NovelChapterEntity>>(
      this.apiPath + "/search" + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  // this is used for getting the chapter for reading
  async findCurrentParagraphs(
    id: string,
    params: NovelRevisionParagraphListParams,
    auth?: string,
  ) {
    const resp = await axios.get<CollectionResult<NovelParagraphEntity>>(
      this.apiPath + `/${id}/revision/current/paragraph` + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  getRevisionManager(chapterId: string) {
    return new NovelRevision(this.apiPath + `/${chapterId}`);
  }

  getParagraphManager(chapterId: string) {
    return new NovelParagraph(this.apiPath + `/${chapterId}`);
  }
}

export const NovelChapter = new NovelChapterClass();

class NovelChapterApprovalClass extends EntityView<
  NovelChapterApprovalListParams,
  NovelChapterApprovalEntity
> {
  constructor() {
    super("/novel/approval");
  }

  async resolve(
    id: string,
    version: number,
    additional: {
      decision?: ReportAction;
      // only relevant for rejections
      details?: string;
    },
    auth: string,
  ) {
    const resp = await axios.post<Result<NovelChapterApprovalEntity>>(
      this.apiPath + `/${id}`,
      {
        version,
        approval: additional.decision,
        staffDetails: additional.details,
      },
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }
}

export const NovelChapterApproval = new NovelChapterApprovalClass();
