import { Achievement, type AchievementEntity } from "~/src/api";
import { useGenericCache } from "./generic";

export function useAchievementCache(timeout = 1000 * 60) {
  const cache = useGenericCache<string, AchievementEntity>("chapters", timeout);

  async function getByid(id: string, authenticated = false) {
    const cached = cache.get(id);
    if (cached) return cached;

    const achivement = await Achievement.get(
      id,
      ["*"],
      authenticated ? await getTokenOrThrow() : undefined,
    );
    cache.store(id, achivement);
    return achivement;
  }

  return {
    cache,
    getByid,
  };
}
