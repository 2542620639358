import { initializeApp, type FirebaseApp } from "firebase/app";
import {
  type MessagePayload,
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from "firebase/messaging";
import { Notifications } from "~/src/api";
import { useLocalStorage } from "@vueuse/core";
import { AxiosError } from "axios";
import {
  InternalNotificationPermissionStatus,
  useInternalNotificationsPermissionStatusStorage,
} from "./local/internalNotificationsPermissionStatusStorage";

function debug(...args: any[]) {
  console.debug(
    "%c Firebase ",
    "color: #449977; background-color: #00660077; border-radius: 4px",
    ...args,
  );
}

const firebaseConfigDev = {
  apiKey: "AIzaSyB3p3r-fJeMgYPLbpoZEOi7tkzGkz2NJos",
  authDomain: "namicomi-dev.firebaseapp.com",
  projectId: "namicomi-dev",
  storageBucket: "namicomi-dev",
  messagingSenderId: "602474736399",
  appId: "1:602474736399:web:ed8b13a8a7e7a01a44ec7a",
  measurementId: "G-EMV225JCY5",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyBkeKxVF45MDtoF_wWbQzI9qt4b5UTspJE",
  authDomain: "namicomi-654f7.firebaseapp.com",
  projectId: "namicomi-654f7",
  storageBucket: "namicomi-654f7",
  messagingSenderId: "163880671794",
  appId: "1:163880671794:web:18677fe7bc98a4d0863a2b",
  measurementId: "G-G9WD912EK3",
};

const DEFAULT_FIREBASE_APP = "firebase-app";

enum FirebaseTokenConflict {
  Match,
  NoMatch,
  LocalExistsButRemoteDoesNot,
  RemoteExistsButLocalDoesNot,
  NoneExists,
}

export function useFirebase(key = DEFAULT_FIREBASE_APP) {
  const firebase = useFirebaseApp(key);
  const messaging = useFirebaseMessaging(firebase);

  return {
    firebase,
    messaging,
  };
}

export function useUserDeviceTokenHandlers() {
  const config = useRuntimeConfig();
  const environment =
    config.public.environment === "development" ? "dev" : "prod";

  const storage = useLocalStorage<
    Record<"dev" | "prod", Record<string, string | undefined>>
  >("firebase-device-tokens", { dev: {}, prod: {} });

  function getDeviceTokenForUserFromStorage(userId: string) {
    return storage.value[environment][userId];
  }

  function setDeviceTokenForUserToStorage(userId: string, token: string) {
    storage.value[environment][userId] = token;
  }

  async function registerUserDeviceToken(token: string) {
    await Notifications.setDeviceToken(token, await getTokenOrPrompt());
  }

  async function unregisterUserDeviceToken(token: string) {
    await Notifications.removeDeviceToken(token);
  }

  return {
    getDeviceTokenForUserFromStorage,
    setDeviceTokenForUserToStorage,
    registerUserDeviceToken,
    unregisterUserDeviceToken,
  };
}

export function useFirebaseApp(key = DEFAULT_FIREBASE_APP) {
  const config = useRuntimeConfig();

  return initializeApp(
    config.public.environment === "development"
      ? firebaseConfigDev
      : firebaseConfigProd,
  );
}

export function useFirebaseMessaging(firebase: MaybeRef<FirebaseApp>) {
  const { track } = useDevFunctions();
  const config = useRuntimeConfig();
  const nuxtApp = useNuxtApp();
  const app = nuxtApp.$app();
  const token = ref<string | null>(null);

  // getMessaging attaches DOM event listeners, so it's not SSR safe
  const messaging = computed(() =>
    import.meta.server || !("serviceWorker" in navigator)
      ? null
      : getMessaging(toValue(firebase)),
  );
  const loggedUser = useLoggedUser();
  const internalNotificationsPermissionStatusStorage =
    useInternalNotificationsPermissionStatusStorage();
  const onActivatedHooks: ((token: string) => any)[] = [];
  const onMessageHooks: ((payload: MessagePayload) => any)[] = [];
  const batchOnMessageHooks: ((payloads: MessagePayload[]) => any)[] = [];

  const enabled = ref(false);
  const isActivated = ref(false);

  track("Starting firebase...");

  watchEffect(() => {
    if (!messaging.value) return;

    track("Messaging is available");

    onMessage(messaging.value, (payload) => {
      track("Message received.", payload);

      if (!enabled.value) return;
      onMessageHooks.forEach((hook) => hook(payload));
    });
  });

  const {
    getDeviceTokenForUserFromStorage,
    setDeviceTokenForUserToStorage,
    registerUserDeviceToken,
    unregisterUserDeviceToken,
  } = useUserDeviceTokenHandlers();

  async function activateServiceWorker() {
    track("Activating service worker...");

    return await activateServiceWorkerWithConfiguration(
      config.public.environment === "development"
        ? "/firebase-messaging-sw-dev.js"
        : "/firebase-messaging-sw.js",
    );
  }

  async function activateFirebaseMessaging() {
    track("Activating firebase messaging...");

    token.value = await getFirebaseToken();
    await registerTokenForLoggedUser(token.value);
    onActivatedHooks.forEach((hook) => hook(token.value!));
    isActivated.value = true;

    track("Firebase messaging activated");
  }

  function getVapidKey() {
    return config.public.environment === "development"
      ? "BNabHrmXCyjF-A0IHGMGU9IsEDk8Y2caPNtCAgku3qPN6HGcfTSqSniVD05CxKbS7pCuSsnEcWUuH0njOdVfz5c"
      : "BBWOHySDC2EkyngsvF1JO9894U1Ir23MoreuKBzNkvLO18FxrsKzbyPBge9Gxs5QzcOOkPl4hkovZsFJreaHKT8";
  }

  async function getFirebaseToken() {
    track("Getting firebase token...");

    assertDefined(messaging.value, "messaging_not_initialized");
    const registration = await activateServiceWorker();
    assertDefined(registration, "no_sw_registration");

    const token = await getToken(messaging.value, {
      vapidKey: getVapidKey(),
      serviceWorkerRegistration: registration,
    });

    track("Firebase token retrieved:", token);

    return token;
  }

  async function registerTokenForLoggedUser(token: string) {
    track("Registering token for logged user...");

    const existingToken = getSavedDeviceTokenOfLoggedUser();
    const conflict = getFirebaseTokenConflict(existingToken, token);

    track("Conflict flag:", conflict);

    if (
      conflict === FirebaseTokenConflict.NoMatch ||
      conflict === FirebaseTokenConflict.RemoteExistsButLocalDoesNot
    ) {
      track("Saving token to logged user...");
      saveDeviceTokenToLoggedUser(token);
    }

    if (conflict === FirebaseTokenConflict.NoMatch) {
      track("Removing token from logged user...");
      await removeDeviceTokenFromUser(token);
    }

    track("Attempting to set token to user device tokens...");

    await attemptToSetFirebaseTokenToUserDeviceTokensAndDoNothingIfItAlreadyExists(
      token,
    );

    track("Token registered for logged user");
  }

  function getFirebaseTokenConflict(local?: string, remote?: string) {
    return local
      ? remote
        ? local === remote
          ? FirebaseTokenConflict.Match
          : FirebaseTokenConflict.NoMatch
        : FirebaseTokenConflict.LocalExistsButRemoteDoesNot
      : remote
        ? FirebaseTokenConflict.RemoteExistsButLocalDoesNot
        : FirebaseTokenConflict.NoneExists;
  }

  function saveDeviceTokenToLoggedUser(token: string) {
    assertDefined(loggedUser.value, "not_logged_in");
    setDeviceTokenForUserToStorage(loggedUser.value.id, token);
  }

  function getSavedDeviceTokenOfLoggedUser() {
    assertDefined(loggedUser.value, "not_logged_in");
    return getDeviceTokenForUserFromStorage(loggedUser.value.id);
  }

  async function attemptToSetFirebaseTokenToUserDeviceTokensAndDoNothingIfItAlreadyExists(
    token: string,
  ) {
    try {
      await registerUserDeviceToken(token);
    } catch (e) {
      track("Error while setting token to user device tokens...");

      // Not an axios request error, we don't care
      if (!(e instanceof AxiosError)) throw e;
      // The response is not a json of expected type, throw
      if (
        !e.response ||
        typeof e.response !== "object" ||
        !("message" in e.response.data)
      )
        throw e;
      // The response is irrelevant to what we care about
      // TODO: replace message with resolution from https://namicomi.atlassian.net/browse/FE-523
      if (
        e.response.data.message !==
        "The Devicetoken for receiving Push notifications could not be registered"
      )
        throw e;
    }
  }

  async function removeDeviceTokenFromUser(token: string) {
    await unregisterUserDeviceToken(token);
  }

  async function begForEnablePush() {
    if (
      internalNotificationsPermissionStatusStorage.value !==
      InternalNotificationPermissionStatus.Default
    ) {
      return;
    }

    const action = await app?.prompt(
      "Would you like to enable notifications?",
      {
        icon: "wink",
        forceCloseKey: "no",
        buttons: {
          no: {
            buttonType: "secondary",
            buttonText: "No",
          },
          enable: {
            buttonType: "primary",
            buttonText: "Enable",
          },
        },
      },
    );

    if (action !== "enable") {
      internalNotificationsPermissionStatusStorage.value =
        InternalNotificationPermissionStatus.Denied;
      return;
    }

    internalNotificationsPermissionStatusStorage.value =
      InternalNotificationPermissionStatus.Granted;

    await activateFirebaseMessaging();
  }

  async function tryActivatePush() {
    if (isActivated.value || Notification.permission !== "granted") return;

    internalNotificationsPermissionStatusStorage.value =
      InternalNotificationPermissionStatus.Granted;
    await activateFirebaseMessaging();
  }

  watch(
    [loggedUser, enabled],
    () => {
      if (!enabled.value) return;
      tryActivatePush();
    },
    { immediate: true },
  );

  return {
    onMessage: (hook: (payload: MessagePayload) => void) => {
      onMessageHooks.push(hook);
    },
    onActivated: (hook: (token: string) => any) => {
      if (isActivated.value) {
        hook(token.value!);
      }

      onActivatedHooks.push(hook);
    },
    enable: () => (enabled.value = true),
    disable: () => (enabled.value = false),
    token,
    begForEnablePush,
    internalNotificationsPermissionStatusStorage,
  };
}

async function activateServiceWorkerWithConfiguration(path: string) {
  try {
    const registration = await navigator.serviceWorker.register(path);
    await navigator.serviceWorker.ready;
    return registration;
  } catch (e) {
    debug("Unable to register service worker");
    console.error(e);
    return null;
  }
}
