declare global {
  interface Window {
    ___enableDevFunctions: boolean;
  }
}

const isEnabled = () =>
  import.meta.env.DEV ||
  (typeof window !== "undefined" && window.___enableDevFunctions);

export function useDevFunctions() {
  function ifDev(func: () => any) {
    if (!isEnabled()) return;
    func();
  }

  function track(...args: any[]) {
    ifDev(() => console.log(...args));
  }

  return {
    ifDev,
    track,
  };
}
