import { EntityViewSet, Headers } from "../generic";
import type {
  NovelParagraphListParams,
  NovelParagraphCreateParams,
  NovelParagraphEntity,
  NovelBookmarkCreateParams,
  NovelBookmarkEntity,
  Result,
  NovelRevisionEntity,
} from "../../types";
import axios from "~/src/api/core/axios";
import { assertSuccess } from "~/src/api";

export class NovelParagraph extends EntityViewSet<
  NovelParagraphListParams,
  NovelParagraphCreateParams,
  {},
  NovelParagraphEntity
> {
  constructor(path: string) {
    super(path + "/paragraph");
  }

  override async update(
    id: string,
    body: {},
    auth: string,
  ): Promise<NovelParagraphEntity> {
    throw new Error("Paragraphs are immutable");
  }
}
